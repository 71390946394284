<template>
  <b-card>
    <h4 class="mb-0">
      Edit Jam Belajar
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <b-form-group label="Tipe Jam Pelajaran" label-for="study_hour_type_id">
              <validation-provider #default="{ errors }" name="study_hour_type_id" rules="required">
                <b-form-input id="study_hour_type_id" v-model="dataStudent.study_hour_type_id" :state="errors.length > 0 ? false : null"
                  placeholder="Tipe Jam Pelajaran" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Jam" label-for="hour">
              <validation-provider #default="{ errors }" name="Jam" rules="required">
                <b-form-input id="hour" v-model="dataStudent.hour" :state="errors.length > 0 ? false : null"
                  placeholder="Jam" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Jam Mulai" label-for="start">
              <validation-provider #default="{ errors }" name="Jam Mulai" rules="required">
                <b-form-input id="start" v-model="dataStudent.start" :state="errors.length > 0 ? false : null"
                  placeholder="Jam Mulai" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Jam Akhir" label-for="end">
              <validation-provider #default="{ errors }" name="Jam Akhir" rules="required">
                <b-form-input id="end" v-model="dataStudent.end" :state="errors.length > 0 ? false : null"
                  placeholder="Jam Akhir" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Deskripsi" label-for="description">
              <validation-provider #default="{ errors }" name="Deskripsi" rules="required">
                <b-form-input id="description" v-model="dataStudent.description" :state="errors.length > 0 ? false : null"
                  placeholder="Deskripsi" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>                                          
          
                                           
          </b-col>       
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Cancel
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    dataCountries,
    dataProvinces,
    dataCities,
    dataDistrics,
    dataSubDistrics,
    required,
    name: '',
    dataStudent: {
      nip: '',
      name: '',
      nickname: '',
      first_title: '',
      last_title: '',
      gender: '',
      birth_place: '',
      birth_date: '',
      education_level: '',
      educational_institution: '',
      ktp_number: '',
      religion: '',
      ethnic: '',
      nationality: '',
      marital_status: '',
      is_active: '',
      type: '',
      province: '',
      city: '',
      district: '',
      subdistrict: '',
      rw: '',
      rt: '',
      address: '',
      phone_1: '',
      phone_2: '',
      whatsapp: '',
    },
    locations: {
      province: '',
      city: '',
      district: '',
      subdistrict: '',
    },
    dataGender: [
      { value: 'M', text: 'Laki-laki' },
      { value: 'F', text: 'Perempuan' },
    ],
    dataReligion: [
      { value: 1, text: 'Islam' },
      { value: 2, text: 'Protestan' },
      { value: 3, text: 'Katolik' },
      { value: 4, text: 'Hindu' },
      { value: 5, text: 'Buddha' },
      { value: 6, text: 'Khonghucu' },
    ],
    dataEducation: [
      { value: '1', text: 'SD' },
      { value: '2', text: 'SMP' },
      { value: '3', text: 'SMA' },
      { value: '4', text: 'S1' },
      { value: '5', text: 'S2' },
      { value: '6', text: 'S3' },
    ],
    dataMaritalStatus: [
      { value: 1, text: 'Menikah' },
      { value: 2, text: 'Belum Menikah' },
    ],
    dataIsActive: [
      { value: 1, text: 'Aktif' },
      { value: 0, text: 'Tidak Aktif' },
    ],
  }),
  computed: {
    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
  },
  created() {
    this.getStudentDetail()
  },
  methods: {
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/employees/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.nip = data.nip
      this.dataStudent.name = data.name
      this.dataStudent.nickname = data.nickname
      this.dataStudent.first_title = data.first_title
      this.dataStudent.last_title = data.last_title
      this.dataStudent.gender = data.gender
      this.dataStudent.birth_place = data.birth_place
      this.dataStudent.birth_date = data.birth_date.substr(0, 10)
      this.dataStudent.education_level = data.education_level
      this.dataStudent.educational_institution = data.educational_institution
      this.dataStudent.ktp_number = data.ktp_number
      this.dataStudent.religion = data.religion
      this.dataStudent.ethnic = data.ethnic
      this.dataStudent.nationality = data.nationality
      this.dataStudent.marital_status = data.marital_status
      this.dataStudent.is_active = data.is_active
      this.dataStudent.type = data.type
      this.dataStudent.province = data.province
      this.dataStudent.city = data.city
      this.dataStudent.district = data.district
      this.dataStudent.subdistrict = data.subdistrict
      this.dataStudent.rw = data.rw
      this.dataStudent.rt = data.rt
      this.dataStudent.address = data.address
      this.dataStudent.phone_1 = data.phone_1
      this.dataStudent.phone_2 = data.phone_2
      this.dataStudent.whatsapp = data.whatsapp
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            // Object.keys(this.locations).forEach(key => {
            //   dataFormStudent.append(key, this.locations[key])
            // })
            dataFormStudent.append('_method', 'PUT')
            await this.$http.post(`/employees/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tambah Data Pegawai Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'data-pegawai' })
          } catch (err) {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tambah Data Pegawai Tidak Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            console.log(err)
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
